
import SearchResult from "@/components/search/v2/SearchResult"
import OabatTextFieldSearchIcon from "@/components/base/design/OabatTextFieldSearchIcon"
import TypingPlaceholder from "@/components/base/input/TypingPlaceholder"
import BusinessLogo from "@/components/business/BusinessLogo"

import { getChannelBySlug } from "@/services/v2/channel.service"
import { getApi } from "@/plugins/api"

const debounce = require("lodash.debounce")

import {
    defineComponent,
    useFetch,
    ref,
    inject,
    provide,
    computed,
} from "@nuxtjs/composition-api"
import { Search } from "@/models/search"

export default defineComponent({
    name: "BusinessListBlok",
    components: {
        OabatTextFieldSearchIcon,
        BusinessLogo,
        SearchResult,
        TypingPlaceholder,
    },
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const channelSlug =
            props.blok.channel || inject("channelSlug", undefined)
        const search = ref(
            new Search({
                type: "business_site",
                channel: channelSlug,
            })
        )

        const schedule = ref([])
        try {
            schedule.value = JSON.parse(
                props.blok.schedule?.content?.at(0)?.content?.at(0)?.text ?? []
            )
        } catch (e) {
            schedule.value = []
        }

        const totalBusinesses = ref(0)
        const previewBusinesses = ref([])
        const hasResults = ref(true)
        const channelName = ref(null)
        useFetch(async () => {
            if (search.value.data.length > 0) return
            await search.value.execute()
            totalBusinesses.value = search.value.meta?.total ?? 0
            previewBusinesses.value = search.value.data?.slice(0, 3) ?? []
            hasResults.value = totalBusinesses.value > 0
            getApi()
                .run(getChannelBySlug, channelSlug)
                .then((channel) => {
                    channelName.value = channel.name
                })
                .catch(() => {
                    channelName.value = null
                })
        })
        provide(
            "schedule",
            computed(() => schedule.value)
        )
        provide(
            "jobFairName",
            computed(() => channelName.value)
        )
        return {
            hasResults,
            search,
            totalBusinesses,
            previewBusinesses,
        }
    },
    data: () => ({
        searchInput: "",
        showSuggestions: true,
        debouncedSearchBusiness: null,
    }),
    created() {
        this.debouncedSearchBusiness = debounce(this.searchBusiness, 300, {
            leading: false,
            trailing: true,
        })
    },
    computed: {
        suggestions() {
            if (this.blok.suggestions) {
                return this.blok.suggestions.split(",").map((tag) => tag.trim())
            }
            return ["Sparkasse", "Bäcker", "BMW", "DB", "dm", "Brillux"]
        },
        headlineString() {
            if (this.blok.headline) {
                return this.blok.headline.replace(
                    "{total}",
                    this.totalBusinesses
                )
            }
            return `Über ${this.totalBusinesses} Firmen`
        },
        subtitleString() {
            return this.blok.subtitle ?? "freuen sich auf deine Nachricht"
        },
        resultRows() {
            if (this.blok.rows) return Number(this.blok.rows)
            return undefined
        },
    },
    methods: {
        async searchBusiness() {
            this.search.setTerm(this.searchInput || undefined)
            this.search.execute()
        },
    },
})
